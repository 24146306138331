import { React, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from "../button/Button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import "./Navigation.css";
import { faBars, faHatWizard } from '@fortawesome/free-solid-svg-icons';

export default function Navigation() {

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    }
  }, []);

  const onMobileLinkClicked = () => {
    setIsMenuOpen(false);
  }

  return (
    <>
      <div className='navigation-main'>
        <Link to="/">
          <div className='navigation-identity'>
            <h2 className='text-color-secondary text-main text-bold'>Nabil Shelim</h2>
          </div>
        </Link>

        {
          windowSize.innerWidth > 1300 ?
            (
              <>
                <div className='navigation-menu'>
                  <Link to="/" className='text-bold'>Home</Link>
                  <Link to="/portfolio" className='text-bold'>Portfolio</Link>
                  <Link to="/blog" className='text-bold'>Blog</Link>
                  <Link to="/about" className='text-bold'>About Me</Link>
                  <Link to="/contact" className='text-bold'>Contact</Link>
                </div>
              </>
            ) :
            (
              <>
                <div className='navigation-menu'>
                  <div className='navigation-hamburger-menu-btn'>
                    {/* <Button onClick={() => {
                      setIsMenuOpen(!isMenuOpen);
                      console.log(isMenuOpen)
                    }}><FontAwesomeIcon icon={faHatWizard} /></Button> */}
                    <FontAwesomeIcon onClick={() => {
                      setIsMenuOpen(!isMenuOpen);
                      console.log(isMenuOpen)
                    }} icon={faBars} />
                  </div>
                </div>
              </>
            )
        }
      </div>
      {
          windowSize.innerWidth <= 1300 && isMenuOpen ?
            <div className='navigation-menu navigation-hamburger-menu'>
              <Link onClick={onMobileLinkClicked} to="/" className='text-bold'>Home</Link>
              <Link onClick={onMobileLinkClicked} to="/portfolio" className='text-bold'>Portfolio</Link>
              <Link onClick={onMobileLinkClicked} to="/blog" className='text-bold'>Blog</Link>
              <Link onClick={onMobileLinkClicked} to="/about" className='text-bold'>About Me</Link>
              <Link onClick={onMobileLinkClicked} to="/contact" className='text-bold'>Contact</Link>
            </div>
            :
            <></>
        }
    </>
  )
}

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}