import React from 'react'
import { Outlet } from "react-router-dom";
import Footer from '../footer/Footer';

import Navigation from '../navigation/Navigation';
import "./Layout.css";

export default function Layout() {
  return (
    <>
      <Navigation/>
      <div className='layout-container'>
        <Outlet/>
      </div>
      <Footer/>
    </>
  )
}
