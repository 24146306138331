import React from 'react';
import { Helmet } from 'react-helmet-async';
import metaData from "../../datas/metaData.json";

export default function HelmetData({title, descripton, canonicalLink}) {
    return (
        <Helmet>
            <title>{metaData.author} | {title}</title>
            <meta name="description" content={descripton} />
            <link rel="canonical" href={canonicalLink} />
        </Helmet>
    )
}
