import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function Button(props) {

  const navigate = useNavigate();

  const navigateTo = () => {
    navigate(props.to);
  }

  return (
    <div>
        <button type={props.type} className={`btn ${props.center && "btn-center"} ${props.wide && "btn-wide"}`} onClick={props.to ? navigateTo : props.onClick}>{props.children}</button>
    </div>
  )
}
