import React from 'react';
import PageHeader from "../../components/pageheader/PageHeader";
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag, faUser } from '@fortawesome/free-solid-svg-icons';
import HelmetData from "../../components/helmet/HelmetData";

import metaData from "../../datas/metaData.json";
import "./About.css";

export default function About() {
  const navigate = useNavigate();

  const onClick = (path) => {
    navigate(path)
  }
  const data = new Date();
  const experience = data.getFullYear() - metaData.startYear;


  return (
    <>
      <HelmetData
        title="About Me"
        descripton="Know more about my experience in making games and in programming in general"
        canonicalLink="https://www.nabilshelim/#/about"
      />
      <PageHeader title="About Me" icon={faUser} />
      <div id="About" className='about-main'>
        <div className="about-info">
          <h2>My name is {metaData.author}.</h2>
          <p>Hey there! I'm a self-taught programmer with a love for all things game development. Over the past {experience} years, I've taken my passion for programming and turned it into a full-blown obsession with creating games. And let me tell you, it's been an exciting ride!</p>
          <p>I've got a solid background in Unity game engine, but I love to branch out and try new things. Hence, I have also dabbled in the world of Godot and Unreal Engine</p>
          <p>I've worked with all sorts of clients and startup indie studios, lending my expertise to bring their <strong className='text-color-secondary'>Game Ideas</strong> to <strong className='text-color-secondary'>life</strong>. And as a freelancer, I get the thrill of taking on new projects and challenges all the time.</p>
          <p>I'm always looking to improve my skills and take on new challenges in the world of game development. Whether I'm working with a team or flying solo, I bring my passion, creativity, and technical know-how to every project.</p>
          <h2 className='text-color-secondary'>My expertise</h2>
          <ul>
            <div className='text-content about-experience'><FontAwesomeIcon className="about-flag" icon={faFlag} /> <div>{experience} years of experience.</div></div>
            <div className='text-content about-experience'><FontAwesomeIcon className="about-flag" icon={faFlag} /> <div>Highly proficient at using C#.</div></div>
            <div className='text-content about-experience'><FontAwesomeIcon className="about-flag" icon={faFlag} /> <div>Comfortable at programming with C++.</div></div>
            <div className='text-content about-experience'><FontAwesomeIcon className="about-flag" icon={faFlag} /> <div>Experience making gameplay mechanics from multiple genres.</div></div>
            <div className='text-content about-experience'><FontAwesomeIcon className="about-flag" icon={faFlag} /> <div>Extensive experience using Unity game engine. You can check out the games I have made over here: <span className='text-color-secondary' onClick={() => onClick("/portfolio")}>Portfolio</span></div></div>
            <div className='text-content about-experience'><FontAwesomeIcon className="about-flag" icon={faFlag} /> <div>Familiarity with Godot and Unreal game engines.</div></div>
            <div className='text-content about-experience'><FontAwesomeIcon className="about-flag" icon={faFlag} /> <div>Worked with clients and startup indie studios to bring their game ideas to life. Taking a game from paper, all the way to a finished and polished product.</div></div>
            <div className='text-content about-experience'><FontAwesomeIcon className="about-flag" icon={faFlag} /> <div>Track record of delivering high-quality results.</div></div>
            <div className='text-content about-experience'><FontAwesomeIcon className="about-flag" icon={faFlag} /> <div>Passionate about game development and continuously expanding knowledge and skills.</div></div>
            <div className='text-content about-experience'><FontAwesomeIcon className="about-flag" icon={faFlag} /> <div>Self-starter with a willingness to take on new challenges.</div></div>
            <div className='text-content about-experience'><FontAwesomeIcon className="about-flag" icon={faFlag} /> <div>Intermediate experience with HTML, CSS, javascript, MongoDB, and React.</div></div>
          </ul>
        </div>
        <div className="about-coffee">
          <img src="../media/images/coffee.png" alt="Coffer cup" />
        </div>
      </div>
    </>
  )
}
