import React from 'react';
import Button from '../../components/button/Button';
import HelmetData from '../../components/helmet/HelmetData';

import metaData from "../../datas/metaData.json"
import "./Home.css";

export default function Home() {
  return (
    <>
      <HelmetData
        title="Home"
        descripton={`Hey, I'm ${metaData.author}. Im a Programmer, GameDev, and Freelancer! Have look around!`}
        canonicalLink={"https://www.nabilshelim"}
      />
      <div className='home-main'>
        <div className='home-intro'>
          <div>
            <h2 className='text-main'>Hey, I'm {metaData.author}</h2>
            <h1 className='text-heading text-color-secondary'>Programmer, GameDev, <br /> Freelancer!</h1>
          </div>
          <div className='home-intro-btn'>
            <Button to="/portfolio">Explore!</Button>
          </div>
        </div>
        <div className='home-mascot'>
          <img src="./media/images/wizardhat.png" alt="ClumsWizard Mascot" />
        </div>
      </div>
    </>
  )
}
