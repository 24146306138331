import React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from '../../components/pageheader/PageHeader';
import rehypeRaw from "rehype-raw";
import Markdown from "react-markdown";
import Button from '../../components/button/Button';

import blogMetaData from "../../datas/blogsData.json";
import "./BlogPage.css";

export default function BlogPage() {

    const params = useParams();
    const navigate = useNavigate();

    const blog = blogMetaData.find(x => x.id == params.blogID);
    console.log(blog.content)

    return (
        <>
            <PageHeader title={blog.title}>
                <p>Released on: <strong>{blog.date}</strong></p>
            </PageHeader>
            <div className='blogpage-body'>
                <Markdown children={blog.content.join("<br />")} rehypePlugins={[rehypeRaw]} />
            </div>
            <div className='blogpage-back'>
                <Button center onClick={() => {
                    navigate(`/blog/section/${blog.category}`)
                }}>Back</Button>
            </div>
        </>
    )
}
