import React from 'react';
import { faLegal } from '@fortawesome/free-solid-svg-icons';
import PageHeader from '../../components/pageheader/PageHeader';
import { Link } from 'react-router-dom';
import HelmetData from '../../components/helmet/HelmetData';

export default function Legal() {
    return (
        <>
            <HelmetData
                title="Legal"
                descripton="Legal stuffs! Nothing to be scared of...."
                canonicalLink={"https://www.nabilshelim/#/legal"}
            />
            <PageHeader title="Legal Stuffs" icon={faLegal} />
            <div>
                <h1>Lets get the legal stuff out of the way!</h1>
                <ul>
                    <li><p>"We / us / me / Nabil Shelim / ClumsyWizard" refers to Nabil Mohammed Shelim, the creator of this website and all its content (unless mentioned otherwise).</p></li>
                    <li><p>"Our Website / My Website" refers to <Link to="/">https://nabilshelim.netlify.app/</Link></p></li>
                    <li><p>"Our Games / My Games / My Portfolio" refers to games and other things made by me</p></li>
                    <li><p>"You / User" refers to the person using our website</p></li>
                </ul>
                <br />
                <br />
                <h2>Privacy Policy and Terms Of services:</h2>
                <ul>
                    <li><p>I use Google Analytics to track visits to my website and to improve my website accordingly.</p></li>
                    <li><p>I also use Netlify forms to collect quick messages from you, if you used the quick messaging system on our website.</p></li>
                    <li><p>By using my website you agree to the before-mentioned companies terms of services and privacy policies. If you are unaware of the before-mentioned company's terms of services and privacy policies, please check their respective websites and find out or discontinue using my website.</p></li>
                    <li><p>All the files, assets and logos on my website are provided for being used for learning or in reviews or by others to mention me or my games or write reviews for my games and not provided to be modified or sold.</p></li>
                </ul>
            </div>
        </>
    )
}
