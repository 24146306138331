import { React, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import PageHeader from "../../components/pageheader/PageHeader";
import Button from "../../components/button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward } from "@fortawesome/free-solid-svg-icons";
import HelmetData from "../../components/helmet/HelmetData";

import gameData from "../../datas/gamesData.json";
import "./Game.css";

export default function Game() {
    const navigate = useNavigate();
    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        }
    });

    const params = useParams();
    const fetchedGame = {};
    let pageExists = false;
    gameData.forEach(game => {
        if (params.id == game.id) {
            fetchedGame.category = game.category;
            fetchedGame.markdownFileName = game.markdownFileName;
            fetchedGame.title = game.title;
            fetchedGame.fromDate = game.fromDate;
            fetchedGame.toDate = game.toDate;
            fetchedGame.headerImg = game.header;
            fetchedGame.screenshots = game.screenshots ? game.screenshots.replaceAll("\r", "").replaceAll(" ", "").split(",") : [];
            fetchedGame.link = game.link ? game.link : "";
            fetchedGame.content = game.content.join("<br />");
            fetchedGame.review = game.review;
            fetchedGame.reviewcontact = game.reviewcontact;
            fetchedGame.engine = game.engine;
            fetchedGame.language = game.language;
            pageExists = true;
        }
    });

    if (!pageExists) {
        navigate("/404")
    }

    const headerLink = "./content/games/" + fetchedGame.category + "/" + fetchedGame.markdownFileName + "/" + fetchedGame.headerImg;
    const screenshots = [];
    fetchedGame.screenshots.forEach(screenshot => {
        screenshots.push("./content/games/" + fetchedGame.category + "/" + fetchedGame.markdownFileName + "/" + screenshot)
    });
    const gameLink = fetchedGame.link.replaceAll("\"", "");

    function getExtraImages() {
        return (
            <div className="game-rightside">
                <div className="game-screenshots">
                    {screenshots.map((screenShot, i) => {
                        return (<>
                            <img className="game-img-border" key={i} src={screenShot} />
                        </>)
                    })}
                </div>
            </div>
        )
    }

    return (
        <>
            <HelmetData
                title={fetchedGame.title}
                descripton={fetchedGame.content}
                canonicalLink={`https://www.nabilshelim/#/portfolio/${params.id}`}
            />
            <div className="game-main">
                <div className="game-intro">
                    <PageHeader title={fetchedGame.title}>
                        {
                            fetchedGame.category === "client" ? 
                            <p>{`From: ${fetchedGame.fromDate} | To: ${fetchedGame.toDate}`}</p>
                            :
                            <p>{`Released on: ${fetchedGame.toDate}`}</p>
                        }
                    </PageHeader>
                </div>
                <div className="game-container">
                    <div className="game-leftside">
                        <img className="game-headerImg game-img-border" src={headerLink} alt="" />
                        {
                            windowSize.innerWidth <= 1000 && getExtraImages()
                        }
                        {fetchedGame.link ? <div className="game-link"><a className="btn" href={gameLink} target="_blank">Try it out!</a></div> : null}
                        <div className="game-content">
                            <Markdown children={fetchedGame.content} rehypePlugins={[rehypeRaw]} />
                        </div>
                        {fetchedGame.review != null ? <div className="card game-client-review">
                            <h2>Client testimonial</h2>
                            <br />
                            <p>{fetchedGame.review}</p>
                            <br />
                            <em className="text-color-secondary"><Markdown children={fetchedGame.reviewcontact} rehypePlugins={[rehypeRaw]} /></em>
                        </div> : null}
                    </div>
                    {
                        windowSize.innerWidth > 1000 && getExtraImages()
                    }
                </div>
                <div className="game-back-btn">
                    <Button to={"/portfolio"} center>Back <FontAwesomeIcon icon={faBackward} /></Button>
                </div>
            </div>
        </>
    )
}

function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}