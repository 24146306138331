import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./PageHeader.css";
import { useScrollUp } from '../scrollUp/ScrollUp';

export default function PageHeader(props) {
  useScrollUp();
  return (
    <div className='page-header'>
        <h2 className='text-main'>{props.title} <FontAwesomeIcon icon={props.icon}/></h2>
        {props.children}
    </div>
  )
}
