import React from 'react';
import PageHeader from '../../components/pageheader/PageHeader';
import Social from '../../components/social/Social';
import Button from '../../components/button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faPhone } from '@fortawesome/free-solid-svg-icons';
import HelmetData from '../../components/helmet/HelmetData';

import "./Contact.css";

export default function Contact() {
  return (
    <>
      <HelmetData
        title="Contact"
        descripton="Get in touch and lets get making some awesome games!"
        canonicalLink="https://www.nabilshelim/#/contact"
      />
      <PageHeader title="Contact" icon={faPhone} />
      <div id="Contact" className="contact-intro">
        {/* Social Handles */}
        <div className="contact-social">
          <h2>Get in touch!</h2>
          <p>Have a game idea lingering in your mind that you want to make? Want to focus on making a fan base, design and flesh out your game?</p>
          <br />
          <p>Let me handle the programming and the technical side so that you can focus on all that!</p>
          <br />
          <p className="contact-punchline">Ready? Good, lets get started!</p>
          <Social vertical />
        </div>

        {/* Contact form */}
        <div className="card contact-form">
          <h2>Leave a quick message!</h2>
          <form name="Contact" method="post">
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="Contact" />
            <div className="contact-form-control">
              <input type="text" name="name" placeholder="Your Name" required />
            </div>
            <div className="contact-form-control">
              <input type="email" name="email" placeholder="example@gmail.com" required />
            </div>
            <div className="contact-form-control">
              <textarea name="message" placeholder="Your Message!" cols="30" rows="6" />
            </div>
            <Button wide type="submit">Send <FontAwesomeIcon icon={faPaperPlane} /></Button>
          </form>
        </div>
      </div>
    </>
  )
}
