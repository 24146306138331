import { React, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from '../../components/pageheader/PageHeader';
import Button from "../../components/button/Button"

import "./Blog.css";
import "./BlogSection.css";
import blogMetaData from "../../datas/blogsData.json";

export default function BlogSection() {

  const params = useParams();
  const navigate = useNavigate();

  // Preping the blog sections
  let blogTypes = [];

  for (let i = 0; i < blogMetaData.length; i++) {
    const blog = blogMetaData[i];
    if (blog.category === params.category) {
      if (!blogTypes.find(x => x.metaName === blog.blogName)) {
        let fixedName = blog.blogName.replaceAll("-", " ");
        fixedName = fixedName[0].toUpperCase() + fixedName.substring(1, fixedName.length);
        blogTypes.push({
          metaName: blog.blogName,
          name: fixedName
        });
      }
    }
  }

  // Preping the specific blog section's blogs
  const [currentSelectedBlog, setCurrentSelectedBlog] = useState("None");
  const [showBlogType, setshowBlogType] = useState(true);

  const onTypeClick = (blogName) => {
    setCurrentSelectedBlog(blogName);
    setshowBlogType(false);
  }

  const onBlogClick = (id) => {
    navigate(`/blog/${id}`);
  }

  return (
    <>
      <PageHeader title={params.category[0].toUpperCase() + params.category.substring(1, params.category.length)}></PageHeader>
      <div className='blog-main'>
        {
          showBlogType && blogTypes.map((data, i) => {
            return (
              <>
                <div className='card blog-card' onClick={() => onTypeClick(data.metaName)}>
                  <img src={`./content/blogs/${params.category}/${data.metaName}/icon.png`} alt={`${data.name}`} />
                  <div className='card-title'><h2>{data.name}</h2></div>
                </div>
              </>
            )
          })
        }
        {
          !showBlogType && blogMetaData.map(blog => {
            if (blog.blogName === currentSelectedBlog) {
              return (
                <>
                  <div className='card blog-card' onClick={() => onBlogClick(blog.id)}>
                    <img src={`./content/blogs/${params.category}/${blog.blogName}/icon.png`} alt={`${blog.blogName}`} />
                    <div className='card-title'><h2>{blog.subBlogName}</h2></div>
                  </div>
                </>
              )
            }
            return <></>
          })
        }
      </div>
        <div className='blogsection-back'>
          <Button center onClick={() => {
            if(showBlogType){
              navigate("/blog")
            }
            else{
              setCurrentSelectedBlog("None");
              setshowBlogType(true);
            }
          }}>Back</Button>
        </div>
    </>
  )
}
