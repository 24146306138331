import React from 'react';
import { useNavigate } from 'react-router-dom';
import PageHeader from "../../components/pageheader/PageHeader";
import { faPen } from '@fortawesome/free-solid-svg-icons';
import HelmetData from '../../components/helmet/HelmetData';

import "./Blog.css";
import blogMetaData from "../../datas/blogsData.json";

export default function Blog() {
  const navigate = useNavigate();
  let blogCategories = [];

  for (let i = 0; i < blogMetaData.length; i++) {
    const category = blogMetaData[i].category;
    if (!blogCategories.includes(category)) {
      blogCategories.push(category);
    }
  }

  function onClick(category) {
    navigate(`/blog/section/${category}`);
  }

  return (
    <>
      <HelmetData
        title="Blog"
        descripton="My personal blog page and tutorials that I have made"
        canonicalLink="https://www.nabilshelim/#/blog"
      />
      <PageHeader title="Blog" icon={faPen} />
      {
        blogMetaData.length == 0 ?
          <>
          <div className='blog-empty-main'>
            <h2>Wow! So empty!</h2>
          </div>
          </>
          :
          <>
            <div className='blog-main'>
              {
                blogCategories.map(category => {
                  return (
                    <>
                      <div className='card blog-card' onClick={() => onClick(category)}>
                        <img src={`./media/images/${category}.png`} alt={`${category}`} />
                        <div className='card-title'><h2>{category[0].toUpperCase() + category.substring(1, category.length)}</h2></div>
                      </div>
                    </>
                  )
                })
              }
            </div>
          </>
      }
    </>
  )
}
