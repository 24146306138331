import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab, faInstagram, faTwitter, faReddit } from '@fortawesome/free-brands-svg-icons';

import socialLinks from "../../datas/socialData.json";
import "./Social.css";


library.add(fab, faInstagram, faTwitter, faReddit);

export default function Social(props) {
    return (
        <div className={`social-links ${props.vertical && "social-vertical"} ${props.centerAlign && "social-center-align"}`}>
            {
                socialLinks.map(socialInfo => {
                    return <a className={`${socialInfo.title.toLowerCase()} social-link`} href={socialInfo.link} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={`fa-brands fa-${socialInfo.title.toLowerCase()}`} /> {socialInfo.title}</a>
                })
            }
        </div>
    )
}
