import React from 'react';
import Button from '../../components/button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHomeLg } from '@fortawesome/free-solid-svg-icons';

import "./NotFound.css";
import HelmetData from '../../components/helmet/HelmetData';

export default function NotFound() {
  return (
    <>
      <HelmetData
        title="404"
        descripton="Ohhh no!"
      />
      <div className='notfound'>
        <h2>Ohh no! This should not happen!</h2>
        <Button to={"/"}>Head Back? <FontAwesomeIcon icon={faHomeLg} /></Button>
      </div>
    </>
  )
}
