import React from 'react';
import { HashRouter, Routes, Route } from "react-router-dom";

import Layout from "../components/layout/Layout";
import About from '../pages/about/About';
import Blog from '../pages/blog/Blog';
import Contact from '../pages/contact/Contact';
import Home from '../pages/home/Home';
import Portfolio from '../pages/portfolio/Portfolio';
import Game from '../pages/game/Game';
import Legal from '../pages/legal/Legal';
import NotFound from '../pages/notFound/NotFound';
import BlogSection from '../pages/blog/BlogSection';
import BlogPage from '../pages/blog/BlogPage';

export default function App() {
  return (
    <HashRouter>
        <Routes>
            <Route path="/" element={<Layout/>}>
            <Route exact index element={<Home/>} />
            <Route path='/portfolio' element={<Portfolio/>}/>
            <Route path='/portfolio/:id' element={<Game/>}/>
            <Route path='/blog' element={<Blog/>}/>
            <Route path='/blog/section/:category' element={<BlogSection/>}/>
            <Route path='/blog/:blogID' element={<BlogPage/>}/>
            <Route path='/about' element={<About/>}/>
            <Route path='/contact' element={<Contact/>}/>
            <Route path='/legal' element={<Legal/>}/>
            <Route path='*' element={<NotFound/>}/>
            </Route>
        </Routes>
    </HashRouter>
  )
}