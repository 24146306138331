import React from "react";
import { Link } from "react-router-dom";
import Social from "../social/Social";

import metaData from "../../datas/metaData.json"
import "./Footer.css";

function Footer () {

    const date = new Date();

    return (
        <footer>
            <div className="footer-info">
                <p>© {metaData.startYear} - {date.getFullYear()}, {metaData.author} ({metaData.alias})</p>
                <div className="legal-link"><Link to="legal">Legal Stuffs</Link></div>
            </div>
            <Social centerAlign/>
        </footer>
    )
}

export default Footer